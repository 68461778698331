var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('footer',{staticClass:"bg-secondary-dark"},[_c('div',{staticClass:"container text-white flex flex-wrap pt-8 pb-10"},[_c('Icon',{staticClass:"md:order-first",attrs:{"name":"TsmPrimaryLogo","width":"272","height":"48","viewBox":"24 0 136 24"}}),_c('ul',{staticClass:"order-first lg:order-none w-full md:flex lg:w-auto mb-8 md:mb-10 lg:mb-0 md:mt-8 lg:mt-0 lg:ml-auto lg:pt-2 text-sm",attrs:{"data-cy":"footer-links"}},_vm._l((_vm.footerLinks),function(item){return _c('li',{key:item.id,class:item.class},[(!item.callMethod)?_c('a',{staticClass:"underline hover:no-underline focus:outline-white",attrs:{"href":item.link,"target":"_self"},on:{"click":function($event){_vm.$generalClick({'category': 'Page Interaction',  'label': ("Footer Click - " + (item.linkText))}, $event, item.link);
              _vm.$ga4GeneralClick({
                event: 'footer',
                module: 'legal and copyright',
                action: 'click',
                content_display: item.linkText.toLowerCase()
              })}}},[_vm._v("\n          "+_vm._s(item.linkText))]):_vm._e(),(item.callMethod)?_c('button',{staticClass:"ot-sdk-show-settings underline hover:no-underline focus:outline-white",on:{"click":function($event){_vm.$ga4GeneralClick({
              event: 'footer',
              module: 'legal and copyright',
              action: 'click',
              content_display: item.linkText.toLowerCase()
            })}}},[_vm._v("\n          "+_vm._s(item.linkText)+"\n        ")]):_vm._e()])}),0),_c('ul',{staticClass:"lg:order-none w-full flex lg:ml-auto mt-6 md:mt-0 lg:mt-6 lg:justify-end opacity-75",attrs:{"data-cy":"footer-socials"}},_vm._l((_vm.footerSocials),function(item){return _c('li',{key:item.id,class:item.class},[_c('a',{staticClass:"flex items-center justify-center h-5 w-5 focus:outline-white",attrs:{"href":item.link,"target":"_blank"},on:{"click":function($event){_vm.$generalClick({category: 'Page Interaction', $event: $event, label: ("Social_Media_Footer_" + (item.name))});
            _vm.$ga4GeneralClick({
              event: 'footer',
              module: item.name.toLowerCase(),
              action: 'share'
            })}}},[_c('span',{staticClass:"sr-only"},[_vm._v("Visit TravelSupermarket on "+_vm._s(item.name)+" (opens in a new window)")]),_c('Icon',{attrs:{"name":item.name}})],1)])}),0),_c('div',{staticClass:"w-full flex mt-5 md:pt-5 md:border-t-1 border-grey-light border-opacity-25 opacity-75 text-sm"},[_c('span',[_vm._v("© TravelSupermarket Limited "+_vm._s(_vm.currentYear))]),_c('a',{staticClass:"hidden md:block hover:underline ml-auto mr-6 focus:outline-white font-medium",attrs:{"href":_vm.footerLinks[8].link,"target":"_self"},on:{"click":function($event){_vm.$generalClick({ category: 'Page Interaction', label: ("Footer Click - " + (_vm.footerLinks[8].linkText))}, $event, _vm.footerLinks[8].link);
          _vm.$ga4GeneralClick({
            event: 'footer',
            module: 'legal and copyright',
            action: 'click',
            content_display: _vm.footerLinks[8].linkText.toLowerCase()
          })}}},[_vm._v("\n        "+_vm._s(_vm.footerLinks[8].linkText)+"\n      ")]),_c('a',{staticClass:"hidden md:block hover:underline focus:outline-white font-medium",attrs:{"href":_vm.footerLinks[9].link,"target":"_self"},on:{"click":function($event){_vm.$generalClick({ category: 'Page Interaction', label: ("Footer Click - " + (_vm.footerLinks[9].linkText))}, $event, _vm.footerLinks[9].link);
          _vm.$ga4GeneralClick({
            event: 'footer',
            module: 'legal and copyright',
            action: 'click',
            content_display: _vm.footerLinks[9].linkText.toLowerCase()
          })}}},[_vm._v("\n        "+_vm._s(_vm.footerLinks[9].linkText)+"\n      ")])]),_c('p',{staticClass:"text-sm mt-6 mb-5 opacity-75"},[_c('a',{staticClass:"underline hover:no-underline focus:outline-white font-medium",attrs:{"data-cy":"travel-info-fco-link","href":"https://www.gov.uk/government/organisations/foreign-commonwealth-development-office","target":"_blank","rel":"noopener"},on:{"click":function($event){_vm.$generalClick({'label': 'FCDO link click'}); _vm.$ga4GeneralClick({event: 'footer', module: 'fcdo', action: 'click', content_display: 'fcdo'})}}},[_vm._v("The Foreign, Commonwealth & Development Office (FCDO)")]),_vm._v(" provides valuable up-to-date travel advice for British citizens abroad. It is the best resource for reliable safety and security information. You can also find other important details, such as local laws, passport information, and visa requirements. Stay safe abroad – check the FCDO before you travel.")]),_c('p',{staticClass:"text-sm opacity-75"},[_vm._v("For the latest FCDO advice, follow "),_c('a',{staticClass:"underline hover:no-underline focus:outline-white font-medium",attrs:{"data-cy":"travel-info-twitter-link","href":"https://twitter.com/fcdotravelgovuk","target":"_blank","rel":"noopener"},on:{"click":function($event){_vm.$generalClick({'label': 'FCDO Twitter link click'}); _vm.$ga4GeneralClick({event: 'footer', module: 'fcdo', action: 'click', content_display: 'fcdo twitter'})}}},[_vm._v("@FCDOtravelGovUK")]),_vm._v(" and "),_c('a',{staticClass:"underline hover:no-underline focus:outline-white font-medium",attrs:{"data-cy":"travel-info-facebook-link","href":"https://www.facebook.com/FCDOTravel","target":"_blank","rel":"noopener"},on:{"click":function($event){_vm.$generalClick({'label': 'FCDO Facebook link click'}); _vm.$ga4GeneralClick({event: 'footer', module: 'fcdo', action: 'click', content_display: 'fcdo facebook'})}}},[_vm._v("Facebook.com/FCDOTravel")]),_vm._v(".")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <footer class="bg-secondary-dark">
    <div class="container text-white flex flex-wrap pt-8 pb-10">
      
      <Icon name="TsmPrimaryLogo" width="272" height="48" viewBox="24 0 136 24" class="md:order-first"/>

      <ul data-cy="footer-links" class="order-first lg:order-none w-full md:flex lg:w-auto mb-8 md:mb-10 lg:mb-0 md:mt-8 lg:mt-0 lg:ml-auto lg:pt-2 text-sm">
        <li :class="item.class" v-for="item in footerLinks" :key="item.id">
          <a
              v-if="!item.callMethod"
              :href="item.link"
              @click="
                $generalClick({'category': 'Page Interaction',  'label': `Footer Click - ${item.linkText}`}, $event, item.link);
                $ga4GeneralClick({
                  event: 'footer',
                  module: 'legal and copyright',
                  action: 'click',
                  content_display: item.linkText.toLowerCase()
                })
              "
              target="_self"
              class="underline hover:no-underline focus:outline-white"
            >
            {{item.linkText}}</a>
          <button
            v-if="item.callMethod"
            @click="
              $ga4GeneralClick({
                event: 'footer',
                module: 'legal and copyright',
                action: 'click',
                content_display: item.linkText.toLowerCase()
              })
            "
            class="ot-sdk-show-settings underline hover:no-underline focus:outline-white"
          >
            {{item.linkText}}
          </button>
        </li>
      </ul>

      <ul data-cy="footer-socials" class="lg:order-none w-full flex lg:ml-auto mt-6 md:mt-0 lg:mt-6 lg:justify-end opacity-75">
        <li :class="item.class" v-for="item in footerSocials" :key="item.id">
          <a
            :href="item.link"
            @click="
              $generalClick({category: 'Page Interaction', $event, label: `Social_Media_Footer_${item.name}`});
              $ga4GeneralClick({
                event: 'footer',
                module: item.name.toLowerCase(),
                action: 'share'
              })
            "
            target="_blank"
            class="flex items-center justify-center h-5 w-5 focus:outline-white"
          >
            <span class="sr-only">Visit TravelSupermarket on {{item.name}} (opens in a new window)</span>
            <Icon :name="item.name"/>
          </a>
        </li>
      </ul> 

      <div class="w-full flex mt-5 md:pt-5 md:border-t-1 border-grey-light border-opacity-25 opacity-75 text-sm">
        <span>&copy; TravelSupermarket Limited {{currentYear}}</span>
        <a
          :href="footerLinks[8].link"
          @click="
            $generalClick({ category: 'Page Interaction', label: `Footer Click - ${footerLinks[8].linkText}`}, $event, footerLinks[8].link);
            $ga4GeneralClick({
              event: 'footer',
              module: 'legal and copyright',
              action: 'click',
              content_display: footerLinks[8].linkText.toLowerCase()
            })
          "
          target="_self"
          class="hidden md:block hover:underline ml-auto mr-6 focus:outline-white font-medium"
        >
          {{footerLinks[8].linkText}}
        </a>
        <a
          :href="footerLinks[9].link"
          @click="
            $generalClick({ category: 'Page Interaction', label: `Footer Click - ${footerLinks[9].linkText}`}, $event, footerLinks[9].link);
            $ga4GeneralClick({
              event: 'footer',
              module: 'legal and copyright',
              action: 'click',
              content_display: footerLinks[9].linkText.toLowerCase()
            })
          "
          target="_self"
          class="hidden md:block hover:underline focus:outline-white font-medium"
        >
          {{footerLinks[9].linkText}}
        </a>
      </div>

      <p class="text-sm mt-6 mb-5 opacity-75"><a data-cy="travel-info-fco-link" class="underline hover:no-underline focus:outline-white font-medium" @click="$generalClick({'label': 'FCDO link click'}); $ga4GeneralClick({event: 'footer', module: 'fcdo', action: 'click', content_display: 'fcdo'})" href="https://www.gov.uk/government/organisations/foreign-commonwealth-development-office" target="_blank" rel="noopener">The Foreign, Commonwealth & Development Office (FCDO)</a> provides valuable up-to-date travel advice for British citizens abroad. It is the best resource for reliable safety and security information. You can also find other important details, such as local laws, passport information, and visa requirements. Stay safe abroad – check the FCDO before you travel.</p>

      <p class="text-sm opacity-75">For the latest FCDO advice, follow <a data-cy="travel-info-twitter-link" class="underline hover:no-underline focus:outline-white font-medium" @click="$generalClick({'label': 'FCDO Twitter link click'}); $ga4GeneralClick({event: 'footer', module: 'fcdo', action: 'click', content_display: 'fcdo twitter'})" href="https://twitter.com/fcdotravelgovuk" target="_blank" rel="noopener">@FCDOtravelGovUK</a> and <a data-cy="travel-info-facebook-link" class="underline hover:no-underline focus:outline-white font-medium" @click="$generalClick({'label': 'FCDO Facebook link click'}); $ga4GeneralClick({event: 'footer', module: 'fcdo', action: 'click', content_display: 'fcdo facebook'})" href="https://www.facebook.com/FCDOTravel" target="_blank" rel="noopener">Facebook.com/FCDOTravel</a>.</p>
      
    </div>
  </footer>
</template>

<script>
import Icon from './Icon.vue'

export default {
  components: {
        Icon
  },
  data() {
    return {
      currentYear: new Date().getFullYear(),
      "footerLinks": [
        {
            "linkText": "Contact us",
            "link": "/en-gb/contact-us/",
            "class": "border-b-1 border-grey-light border-opacity-25 md:border-0 py-2 lg:p-0 md:mr-6"
        },
        {
            "linkText": "Security",
            "link": "/en-gb/security/",
            "class": "border-b-1 border-grey-light border-opacity-25 md:border-0 py-2 lg:p-0 md:mr-6"
        },
        {
            "linkText": "Cookie Policy",
            "link": "/en-gb/cookie-policy/",
            "class": "border-b-1 border-grey-light border-opacity-25 md:border-0 py-2 lg:p-0 md:mr-6"
        },
        {
            "linkText": "Manage Cookie Preferences",
            "callMethod": true,
            "link": "#",
            "class": "border-b-1 border-grey-light border-opacity-25 md:border-0 py-2 lg:p-0 md:mr-6"
        },
        {
            "linkText": "Become an affiliate",
            "link": "https://ui.awin.com/merchant-profile/8734",
            "class": "border-b-1 border-grey-light border-opacity-25 md:border-0 py-2 lg:p-0 md:mr-6"
        },
        {
            "linkText": "Site map",
            "link": "/en-gb/sitemap/",
            "class": "border-b-1 border-grey-light border-opacity-25 md:border-0 py-2 lg:p-0 md:mr-6"
        },
        {
            "linkText": "About us",
            "link": "/en-gb/about-us/",
            "class": "border-b-1 border-grey-light border-opacity-25 md:border-0 py-2 lg:p-0 md:mr-6"
        },
        {
            "linkText": "Work with us",
            "link": "https://careers.icetravelgroup.com/",
            "class": "border-b-1 border-grey-light border-opacity-25 md:border-0 py-2 lg:p-0"
        },
        {
            "linkText": "Privacy",
            "link": "/en-gb/privacy-policy/",
            "class": "md:hidden border-b-1 border-grey-light border-opacity-25 md:border-0 py-2 lg:p-0"
        },
        {
            "linkText": "Terms and Conditions",
            "link": "/en-gb/terms/",
            "class": "md:hidden border-b-1 border-grey-light border-opacity-25 md:border-0 py-2 lg:p-0"
        },
      ],
      "footerSocials": [
        {
            "name": "Facebook",
            "link": "https://www.facebook.com/travelsupermarket",
            "class": "mr-2"
        },
        {
            "name": "X-Twitter",
            "link": "https://twitter.com/travelsupermkt",
            "class": "mr-2"
        },
        {
            "name": "Instagram",
            "link": "https://www.instagram.com/travelsupermarket/",
            "class": ""
        }
      ]
    };
  }
};
</script>
